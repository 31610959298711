import React from "react"
import { PrivateRoute } from "@lesmills/gatsby-theme-common"

type Props = {|
  pageContext: Object,
|}

const PrivatePageRedirection = ({ pageContext }: Props) => (
  <PrivateRoute
    component={() => <></>}
    redirectURL={pageContext.redirect}
    loggedInRedirectURL={pageContext.loggedInRedirectURL}
    needVimeoUserTicket={pageContext.needVimeoUserTicket}
  />
)

export default PrivatePageRedirection
